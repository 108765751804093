import React from 'react';
// import gql from 'graphql-tag';
// import { graphql } from 'react-apollo';
import Markdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAmazon} from '@fortawesome/free-brands-svg-icons';
import MuiOutboundLink from '../components/MuiOutboundLink';

import RelatedPlaceCard from '../components/RelatedPlaceCard';
import RelatedPersonCard from '../components/RelatedPersonCard';
import RelatedLoreCard from '../components/RelatedLoreCard';

import Layout from '../components/Layout';
import SEO, {SEODescription, SEOImage} from '../components/SEO';

import { graphql } from 'gatsby';



const useStyles = makeStyles((theme) => ({
  articleContainer: {
    padding: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
  amazonLink: {
    // display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const Book = ({ data }) => {
  const classes = useStyles();
  const book = data.gcms.book;

  return (
    <Layout>

      <SEO 
        title={book.title}
        description={SEODescription(book.description.text)}
        image={SEOImage(book.image.handle)}
        article={true}
      />

      <Paper className={classes.articleContainer}>
        <article>
          <Typography variant="h3" gutterBottom={true}>
            {book.title}
          </Typography>
          <img
            src={`https://media.graphcms.com/resize=w:1200/${book.image.handle}`}
            title={book.image.title}
            style={{ width: '100%' }}
          />
          <Markdown source={book.description.markdown} escapeHtml={false} />

          {book.linkAmazon &&
            <React.Fragment>
              <Typography variant="h4">
                Buy
                </Typography>

              {book.linkAmazon &&
                <Button
                  component={MuiOutboundLink}
                  href={book.linkAmazon}
                  target="_blank" rel="noopener"
                  className={classes.amazonLink}
                  variant="contained"
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={["fab", "amazon"]} />}
                >
                  Amazon
                  </Button>
              }
            </React.Fragment>
          }

          <Divider variant="middle" />



          {book.places.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Places in the book
                </Typography>
              <Grid container spacing={1}>
                {book.places.map(place => (
                  <RelatedPlaceCard
                    key={place.id}
                    place={place}
                  />
                ))}
              </Grid>
            </section>
          }

          {book.people.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                People in the book
                </Typography>
              <Grid container spacing={1}>
                {book.people.map(person => (
                  <RelatedPersonCard
                    key={person.id}
                    person={person}
                  />
                ))}
              </Grid>
            </section>
          }

          {book.lores.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Stories in the book
                </Typography>
              <Grid container spacing={1}>
                {book.lores.map(lore => (
                  <RelatedLoreCard
                    key={lore.id}
                    lore={lore}
                  />
                ))}
              </Grid>
            </section>
          }

        </article>
      </Paper>
    </Layout>
  );
};

export const query = graphql`
  query singleBook($slug: String!) {
    gcms {
      book(where: {slug: $slug}) {
        id
        title
        image {
          handle
          title
        }
        description {
          markdown
          text
        }
        linkAmazon
        lores {
          id
          title
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        places {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        people {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
      }
    }
  }
`;

export default Book;




// const Book = ({ data: { loading, error, book } }) => {
//   const classes = useStyles();

//   if (error) return <h1>Error fetching the book!</h1>;
//   if (!loading) {
//     return (
//       <Paper className={classes.articleContainer}>
//         <article>
//           <Typography variant="h3" gutterBottom={true}>
//             {book.title}
//           </Typography>
//           <img 
//             src={`https://media.graphcms.com/resize=w:1200/${book.image.handle}`} 
//             title={book.image.title} 
//             style={{width: '100%'}}
//           />
//           <Markdown source={book.description.markdown} escapeHtml={false} />

//           { book.linkAmazon && 
//             <React.Fragment>
//               <Typography variant="h4">
//                 Buy
//               </Typography>

//               {book.linkAmazon && 
//                 <Button 
//                   href={book.linkAmazon} 
//                   target="_blank" rel="noopener"
//                   className={classes.amazonLink} 
//                   variant="outlined"
//                   color="primary"
//                   startIcon={<Icon className="fab fa-amazon"/>}
//                 >
//                   Amazon
//                 </Button>
//               }
//             </React.Fragment>
//           }

//           <Divider variant="middle" />



//           {book.places.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Places in the book
//               </Typography>
//               <Grid container spacing={1}>
//                 {book.places.map(place => (
//                   <RelatedCard
//                     title={place.name}
//                     link={`/place/${place.slug}`}
//                     imageHandle={place.gallery[0].handle}
//                     imageTitle={place.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {book.people.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 People in the book
//               </Typography>
//               <Grid container spacing={1}>
//                 {book.people.map(person => (
//                   <RelatedCard
//                     title={person.name}
//                     link={`/person/${person.slug}`}
//                     imageHandle={person.gallery[0].handle}
//                     imageTitle={person.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {book.lores.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Stories in the book
//               </Typography>
//               <Grid container spacing={1}>
//                 {book.lores.map(lore => (
//                   <RelatedCard
//                     title={lore.title}
//                     link={`/lore/${lore.slug}`}
//                     imageHandle={lore.gallery[0].handle}
//                     imageTitle={lore.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//         </article>
//       </Paper>
//     );
//   }
//   return <h2>Loading book...</h2>;
// };

// export const singleBook = gql`
//   query singleBook($slug: String!) {
//     book(where: {slug: $slug}) {
//       id
//       title
//       image {
//         handle
//         title
//       }
//       description {
//         markdown
//       }
//       linkAmazon
//       lores {
//         title
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       places {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       people {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//     }
//   }
// `;

// export default graphql(singleBook, {
//   options: ({ match }) => ({
//     variables: {
//       slug: match.params.slug,
//     },
//   }),
// })(Book);